<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <TemplateFiveList :type="'radio'" :list-data="listData" :selectValue="listSelect" @change="listChange"></TemplateFiveList>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
          <!--          &lt;!&ndash; 计算结果 &ndash;&gt;-->
          <!--          <div class="CalculationResultsBox" v-if="countText || countText !== ''">-->
          <!--            <div class="CalculationResults"><span>计算结果</span></div>-->
          <!--            &lt;!&ndash; 具体标准 &ndash;&gt;-->
          <!--            <div>-->
          <!--              <div class="CalculationDetail"><span></span>{{result}}分 {{countText}}</div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash;            <div>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <div class="CalculationDetail"><span></span>具体标准：</div>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
          <!--          </div>-->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults :countText="countTextT" :countTextList="[]" v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>

  </div>

</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateFiveList from "@/components/Template5/TemplateFiveList";
import CalculationResults from '@/components/CalculationResults'
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      countTextT: '',
      listSelect: [],
      listData: [
        {
          title: '感觉',
          tips: '1、完全受限：意识丧失或使用镇痛药而对疼痛没有反应，几乎全身体无法感觉疼痛<br/>' +
              '2、非常受限：只对疼痛刺激有反应，呻吟、烦躁不安，>1/2体表面积无痛感。<br/>' +
              '3、轻度受限：对指令性语言有反应，但不能总是用语言表达不舒服，或有1-2个肢体无法感觉疼痛。<br/>' +
              '4、未受损伤：可完全反应语言系统，不影响患者的表达或不适。\n',
          columns: ['完全受限', '非常受限', '轻度受限', '未受损'],
          fenzhi: [1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '潮湿',
          tips: '1、持续潮湿：皮肤总是被汗水、尿液或粪便浸湿，每次翻身都会发现皮肤处在潮湿环境中。<br/>' +
              '2、非常潮湿：皮肤经常被汗水、尿液或粪便浸湿，中单至少每一班需要更换一次，尿布潮湿偶尔更换。<br/>\n'+
              '3、偶有潮湿：皮肤偶尔被汗水、尿液或粪便浸湿，中单1天约额外更换一次，尿布潮湿经常更换。<br/>\n' +
              '4、很少潮湿：皮肤通常保持干燥，中单按常规更换，尿布总是保持干燥。<br/>\n',
          columns: ['持续潮湿', '潮湿', '有时潮湿', '很少潮湿'],
          fenzhi: [1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '活动能力',
          tips: '1、  卧床：被限制在床上。<br/>' +
              '2、坐位：步行困难，无法自行坐入椅子或轮椅内，需他人协助才能完成。<br/>' +
              '3、偶尔行走：白天偶尔可步行短距离，有时需协助，大部分是躺在床上或坐在椅子上。<br/>' +
              '4、经常活动：1天至少到病房外步行2次，醒着时至少每2小时室外活动。\n',
          columns: ['限制卧床', '坐位', '偶尔行走', '经常行走'],
          fenzhi: [1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '移动力',
          tips: '1、完全无法移动：在无人协助情况下，病人完全不能改变身体或四肢的位置。<br/>\n' +
              '2、严重受限：偶尔能轻微改变身体或四肢的位置，但不能经常改变或独立地改变体位。<br/>\n' +
              '3、轻度受限：经常可独自稍微改变身体的位置。<br/>\n' +
              '4、未受限：不需协助可经常大范围随意改变身体的位置。\n',
          columns: ['完全无法移动', '严重受限', '轻度受限', '未受限'],
          fenzhi: [1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '营养状态',
          tipsWeiZhi: 'top-start',
          tips: '1、非常差：从未吃过完整的一餐，没有额外补充流质食物；禁食或只进食流质或静脉输液>5天。<br/>\n' +
              '2、可能缺乏：很少吃完一餐饭，一般仅吃所供食物的1/2；流质或管饲饮食供应少于身体需要。<br/>\n' +
              '3、充足：大多数时间所吃食物>1/2所供食物，维持鼻饲饮食或TPN治疗，摄取量可满足大部分营养所需。<br/>\n' +
              '4、丰富：每餐均能吃完或基本吃完；从不少吃一餐；每天常吃≥4人份的肉类；不要求加餐。\n',
          columns: ['非常差', '可能缺乏', '充足', '丰富'],
          fenzhi: [1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '摩擦力和剪切力',
          tips: '1、有问题：需要中度到极大的协助移动身体，坐在床上或椅子上经常会下滑。<br/>\n' +
              '2、有潜在问题：移动时需轻微协助，大部分时间在床上或椅子上能维持良好的体位，但偶尔有向下滑动。<br/>\n' +
              '3、无明显问题：在床上或椅子里能够独立移动，移动时有足够的肌力完全抬举身体及肢体，坐在床上或椅子上都能保持良好的体位。\n',
          tipsWeiZhi: 'top-start',
          columns: ['有问题', '有潜在问题', '无明显问题'],
          fenzhi: [1,2,3] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },

      ],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      columns: ['正常或轻伤', '中度创伤', '重度创伤'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      fenzhiList: [],
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateFiveList,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    listChange (result, fenzhi) {
      this.show=false
      this.listSelect = result //结果数组
      this.fenzhiList = fenzhi  //分值数组
    },
    coumputTetxt () {
      this.show=true
      let fenshu = 0
      function calculateScore(data, select) {
        select.value.forEach(item => {
          const index = data.columns.indexOf(item);
          if (index !== -1) {
            fenshu += data.fenzhi[index];
          }
        });
      }

      this.listSelect.forEach(value => {
        const data = this.listData.find(d => d.title === value.title);
        if (data) {
          calculateScore(data, value);
        }
      });
      if (fenshu <= 9) {
        this.countText = `极度危险，每班评估一次，病情变化时随时评估`
      } else if (fenshu >= 10 && fenshu <= 12) {
        this.countText = '高度危险，每天评估一次'
      } else if (fenshu >= 13 && fenshu <= 14) {
        this.countText = '中度危险，每周评估两次'
      } else if (fenshu >= 15 && fenshu <= 18) {
        this.countText = '轻度危险，每周评估一次'
      }
      this.result = fenshu
      this.countTextT = `${this.result}分 ${this.countText}`
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'ZQXCUBXQ',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          this.listSelect = res.data.content.data
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          // this.result = res.data.result.value
          // this.countText = res.data.result.result
          this.coumputTetxt()
        }
      }
    },
    async submit () {
      if (this.listSelect.length!==6) {
        Toast.fail('请完善选择')
        return
      }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: this.listSelect
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults:this.countTextT
        }
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
::v-deep .result2Style{
  font-size: 16px !important;
}


.index{
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
.Abutton {

}
</style>
